import React from "react"

const Component = ({ mask, children, small }) => (
  <div
    className={`mask-image mask-${mask.position ?? "center"} mask-number-${
      mask.number ?? "1"
    } ${!!small ? "mask-small" : ""}`}
  >
    {children}
  </div>
)

Component.defaultProps = {
  small: false,
}

export default Component
import React from "react"
import { graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Mask from "../components/maskImage"
import Parallax from '../components/parallax'
import EventDate from "../components/events/eventDate"
import EventTicketLink from '../components/events/eventTicketLink'
import moment from 'moment'
import 'moment/locale/sv'
import getActiveDateData from '../components/mini/getActiveDateData'

  const Price = ({ from, to }) => {
    return (
      <>
        {(!!from || !!to) && (
          <span className="fullPrice">
            <meta itemProp="priceCurrency" content="SEK" />
            {!!from && !!to && <meta itemProp="lowPrice" content={from} />}
            {!!to && !!from && <meta itemProp="highPrice" content={to} />}
            {!!from && !to && <meta itemProp="price" content={from} />}
            {!!to && !from && <meta itemProp="price" content={to} />}
            {from} {from && to && `-`} {to} kronor
          </span>
        )}
      </>
    )
  }

const MoreEvents = ({ event, item, eventData }) => {
  const activeDate = moment(new Date()).format('YYYYMMDD')
  return (
    <div
      className="more-dates-date align-items-center row"
      itemProp="event"
      itemScope
      itemType="https://schema.org/Event"
    >
      <meta itemProp="name" content={parse(event.title)} />
      <div className="col-12">
        <Price from={item.ticketPrice?.from} to={item.ticketPrice?.to} />
        <EventDate eventData={item} />
      </div>
      {!!event.eventData.location?.name && (
        <div
          className="d-none"
          itemProp="location"
          itemScope
          itemType="https://schema.org/Place"
        >
          <p itemProp="name">
            <span>{event.eventData.location?.name}</span>
          </p>
          {event.eventData.location?.locationData?.address && (
            <div
              className="address d-none"
              itemProp="address"
              itemScope
              itemType="https://schema.org/PostalAddress"
            >
              <span itemProp="streetAddress">
                {event.eventData.location?.locationData?.address.streetAddress}
              </span>{' '}
              <span itemProp="addressRegion">
                {event.eventData.location?.locationData?.address.state}
              </span>{' '}
              <span itemProp="postalCode">
                {event.eventData.location?.locationData?.address.postCode}
              </span>
            </div>
          )}
        </div>
      )}
      <div
        className="col-12"
        itemProp="offers"
        itemScope
        itemType="https://schema.org/Offer"
      >
        <EventTicketLink
          activeDate={activeDate}
          activeDateData={{
            ticket: { link: item.ticketLink, date: item.ticketDate },
          }}
        />
      </div>
    </div>
  )
}

const PageTemplate = ({ data: { event }, pageContext }) => {
  const { eventData } = event
  const activeDate = moment(new Date()).format('YYYYMMDD')
  const activeDateData = getActiveDateData({ eventData, activeDate })
  const eventImage = {
    image: getImage(event.eventData?.image?.localFile),
    alt: event.eventData?.image?.altText || ``,
  }
  const isBrowser = typeof window !== "undefined"
  
  if ( isBrowser && 'yes' === event.eventData?.eventRedirect && event.eventData?.eventRedirectLink ) {
    navigate( event.eventData?.eventRedirectLink )
  }

const EventInfo = ( props ) => {
  const { className } = props 
  return (
    <div className={`row eventInfo m-0 ${className ?? ''}`}>
      <div className="col-12 eventInfoTop">
        <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
          <div
            className="location"
            itemProp="location"
            itemScope
            itemType="https://schema.org/Place"
          >
            {event.eventData.location?.name}
          </div>
          <div className="price">
            <Price
              from={activeDateData.ticket?.price?.from}
              to={activeDateData.ticket?.price?.to}
            />
          </div>
          <div className="date">
            <EventDate
              activeDate={activeDate}
              eventData={eventData}
              activeDateData={activeDateData}
            />
          </div>
          <EventTicketLink
            activeDate={activeDate}
            activeDateData={activeDateData}
          />
        </div>
        <div className="more-dates">
          {!!eventData.dates &&
            eventData.dates
              .filter((item) => item.infoDate > activeDate)
              .map((item, i) => (
                <MoreEvents
                  key={`e` + i}
                  event={event}
                  item={item}
                  eventData={eventData}
                />
              ))}
        </div>
      </div>
      <div className="col-12 eventFullInfo">
        <ul>
          {!!event.eventData.info?.date && (
            <li>
              <span className="label">Datum</span>
              <p>
                <EventDate
                  activeDate={activeDate}
                  eventData={event.eventData}
                />
              </p>
            </li>
          )}
          {!!event.eventData.location?.name && (
            <li
              itemProp="location"
              itemScope
              itemType="https://schema.org/Place"
            >
              <span className="label">Plats</span>
              <p itemProp="name">{event.eventData.location?.name}</p>
              {event.eventData.location?.locationData?.address && (
                <div
                  className="address d-none"
                  itemProp="address"
                  itemScope
                  itemType="https://schema.org/PostalAddress"
                >
                  <span itemProp="streetAddress">
                    {
                      event.eventData.location?.locationData?.address
                        .streetAddress
                    }
                  </span>{' '}
                  <span itemProp="addressRegion">
                    {event.eventData.location?.locationData?.address.state}
                  </span>{' '}
                  <span itemProp="postalCode">
                    {event.eventData.location?.locationData?.address.postCode}
                  </span>
                </div>
              )}
            </li>
          )}
          {!!event.eventData.info?.length && (
            <li>
              <span className="label">Längd</span>
              <p>{event.eventData.info?.length}</p>
            </li>
          )}
          {!!event.eventData.info?.seats && (
            <li>
              <span className="label">Platser</span>
              <p>{event.eventData.info?.seats}</p>
            </li>
          )}
          {!!event.eventData?.organizer && (
            <li>
              <span className="label">
                {event.eventData.organizer.length > 1
                  ? 'Arrangörer'
                  : 'Arrangör'}
              </span>
              <p>
                {event.eventData.organizer.map((item, i) => (
                  <span
                    key={`org` + i}
                    itemScope
                    itemProp="organizer"
                    itemType="https://schema.org/Organization"
                  >
                    {item?.organizer?.website && (
                      <OutboundLink
                        href={item?.organizer?.website}
                        target="_blank"
                        rel="noreferrer"
                        itemProp="url"
                      >
                        <span itemProp="name">{item.name}</span>
                      </OutboundLink>
                    )}
                    {!item?.organizer?.website && (
                      <span itemProp="name">{item.name}</span>
                    )}
                    {i < event.eventData.organizer.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </p>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

  const { extra } = event.eventData
  return (
    <Layout
      className="event"
      itemScope
      itemType="https://schema.org/Event"
      language={pageContext.language}
      template={pageContext.template}
    >
      <Seo
        title={event.title}
        template={pageContext.template}
        seo={event?.seo}
        featuredImage={event.eventData?.image?.localFile?.publicURL}
      />
      <link itemProp="url" href={event.uri} />
      <div className="event-top">
        {eventImage?.image && extra.iframe === 'no' && (
          <Mask
            mask={
              event.eventData.extra.removeMask
                ? { position: 'none' }
                : event.eventData?.mask
            }
          >
            <Parallax>
              <GatsbyImage
                className="eventImage"
                loading={'eager'}
                image={eventImage.image}
                alt={eventImage.alt}
                itemProp="image"
              />
            </Parallax>
          </Mask>
        )}
        {extra.iframe === 'yes' && (
          <div className={`ratio ratio-${extra.iframeRatio}`}>
            {parse(extra.html)}
          </div>
        )}
      </div>
      <section
        className="content event"
        id="content"
        itemScope
        itemType="https://schema.org/Event"
      >
        <div className="container-fluid">
          <div className="row event-row justify-content-between">
            <div className="col-12 main-content col-md-7">
              {!!event.eventData?.subTitle && (
                <h3 className="subtitle">{parse(event.eventData.subTitle)}</h3>
              )}
              <h1 className="eventtitle" itemProp="name">
                {parse(event.title)}
              </h1>
              <div className="d-md-none mb-5">
                <EventInfo />
              </div>
              {!!event.eventData?.content && (
                <div itemProp="description">
                  {parse(event.eventData.content)}
                </div>
              )}
            </div>
            <div className="col-12 col-md-4 pb-5 pb-md-0">
              <EventInfo className="d-none d-md-block" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query event($id: String!) {
    event: wpEvent(id: { eq: $id }) {
      id
      title
      uri
      slug
      eventData {
        content
        dateStart
        dateEnd
        dateFull
        eventRedirect
        eventRedirectLink
        extra {
          iframe
          html
          iframeRatio
          removeMask
        }
        mask {
          position
          number
        }
        categories {
          name 
        }
        organizer {
          name
          organizer {
            website
          }
        }
        location {
          name
          locationData {
            address {
              placeId
              postCode
              state
              streetAddress
            }
          }
        }
        dates {
          infoDate
          infoTime
          infoExtraInfo
          ticketDate
          ticketLink
          ticketPrice {
            from
            to
          }
        }
        info {
          length
          seats
          date
          year: date(formatString: "YYYY", locale: "sv")
          month: date(formatString: "MMMM", locale: "sv")
          day: date(formatString: "dddd", locale: "sv")
          dayNum: date(formatString: "D", locale: "sv")
          extraInfo
          time
          dateEnd
          timeEnd
        }
        subTitle
        ticket {
          link
          price {
            from
            to
          }
          date
        }
        subscription {
          name
        }
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 85
                placeholder: BLURRED
              )
            }
          }
        }
      }
      seo {
        canonical
        metaDesc
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          localFile {
            publicURL
          }
        }
        schema {
          pageType
          raw
        }
      }
    }
  }
`

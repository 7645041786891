import moment from 'moment'
import 'moment/locale/sv'

export default function useWindowDimensions({ eventData, activeDate}) {
  if (activeDate > eventData?.dateFull[0] && !!eventData.dates) {
    for (let i = 0; i < eventData.dates.length; i++) {
      const event = eventData.dates[i]
      if ( activeDate > event.infoDate ) {
        continue;
      }

      const theDate = moment(event.infoDate, 'YYYYMMDD').locale('sv')
      const fullDate = theDate.format('YYYYMMDD')
      const weekday = theDate.format('dddd')
      const month = theDate.format('MMMM')
      const day = theDate.format('D')
      const year = theDate.format('yyyy')

      return {
        fullDate: fullDate,
        info: {
          date: weekday + ' ' + day + ' ' + month + ' ' + year,
          year: year,
          month: month,
          day: weekday,
          dayNum: day,
          extraInfo: event.infoExtraInfo,
          time: event.infoTime,
        },
        ticket: {
          link: event.ticketLink,
          date: event.ticketDate,
          price: event.ticketPrice,
        },
      }
    }
    return {
      fullDate : '',
      info: {
        date: '',
        year: '',
        month: '',
        day: '',
        dayNum: '',
        extraInfo: '',
        time: '',
      },
      ticket: {
        link: '',
        date: '',
        price: {
          from: '',
          to: '',
        },
      },
    }
  }
  return {
    fullDate: eventData.info.date,
    info: eventData?.info,
    ticket: eventData?.ticket,
  }
}

import React from 'react'
import moment from 'moment'
import 'moment/locale/sv'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Component = ({ activeDateData, activeDate }) => {
  const now = moment(new Date()).format('YYYYMMDDHHmmss')
  return (
    <>
      {!!activeDateData?.ticket?.link && now >= activeDateData.ticket.date && (
        <OutboundLink href={activeDateData.ticket.link} className="btn" itemProp="url">
          Köp biljetter
        </OutboundLink>
      )}
    </>
  )
}

export default Component

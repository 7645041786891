
import React from "react"
import moment from 'moment'
import 'moment/locale/sv'

const Component = ({ eventData, activeDate, activeDateData }) => {
  const currentActiveDate = activeDate || moment(new Date(), 'YYYYMMDD')
  const dateStart =
    eventData?.dateFull?.filter((date) => date >= currentActiveDate)[0] ||
    eventData?.dateStart || eventData.infoDate
  const dateEnd = eventData?.dateEnd
  return (
    <>
      {!!eventData && !activeDate && (
        <ParseDate date={eventData.infoDate} time={eventData?.infoTime} />
      )}
      {!!activeDateData && activeDate && (
        <ParseDate
          date={activeDateData.fullDate}
          time={eventData?.info?.time}
        />
      )}
      {!activeDateData && activeDate && (
        <>
          <ParseDate date={dateStart} time={eventData?.info?.time} />
          {!!dateEnd && dateEnd > dateStart && (
            <span>
              {' '}
              - <ParseDate date={dateEnd} time={eventData.info.timeEnd} />
            </span>
          )}
        </>
      )}
    </>
  )
}


export const ParseDate = ({ date, time }) => {
  const thisYear = new Date().getFullYear()
  const theDate = moment(date, 'YYYYMMDD').locale('sv')
  const weekday = theDate.format('dddd')
  const month = theDate.format('MMMM')
  const day = theDate.format('D')
  const year = theDate.format('yyyy')
  const startDate = theDate.format('YYYY-MM-DD')
  return (
    <span className="fullDate eventDate">
      <meta itemProp="startDate" content={`${startDate}${!!time ? 'T'+time+':00' : ''}`} />
      <span className="weekday">{weekday}</span>{' '}
      <span className="day">{day}</span> <span className="month">{month}</span>{' '}
      {thisYear != year && <span className="year">{year} </span>}
      {!!time && <span className="time">kl {time}</span>}
    </span>
  )
}

export default Component

Component.defaultProps = {
  activeDateData: false,
}
